<template lang="pug">
q-card#form.modal-form-card
  q-card-section
    form-header(
      ref="form-header",
      @close-form="closeForm",
      :parentData="{ grid: grid, title: header_title, title_icon: 'download_header.svg' }"
    )

  .spinner-container.modal-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  q-card-section
    q-checkbox.form-checkbox(
      v-if="fields && fields.length > 0 && !loading",
      dark,
      v-model="with_filters",
      :label="form.with_filters_label"
    )

  q-card-section(v-show="with_filters && !loading")
    q-form
      component(
        v-for="field in fields",
        :key="field.name",
        :is="getFieldComponent(field.type)",
        @fields-loaded="fieldsLoaded",
        :parentData="{ grid: grid, data: getFieldData(field) }"
      )

  q-card-actions.button-group.row.justify-center(v-show="!loading")
    q-btn.modal-form-cancel(flat, no-caps, :label="form.form_buttons.cancel", @click="closeForm()")
    q-btn.modal-form-submit(flat, no-caps, :label="form.form_buttons.submit", @click="onExport()")
</template>

<script>
import formHeader from "../../shared/forms/formHeader";
import fields from "./fields";

export default {
  components: {
    formHeader,
    fields,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      path: this.parentData.path,
      report_path: this.$store.state.paths["report"],
      // fields: this.parentData.export_attr.data,
      grid: this.parentData.grid,
      // header_title: this.parentData.export_attr.header_title,
      collection_type: this.parentData.export_attr.collection_type,
      sti_type: this.parentData.export_attr.sti_type,
      report_class: this.parentData.export_attr.report_class,
      report_namespace: this.parentData.export_attr.report_namespace,
      export_type: this.parentData.export_attr.export_type,
      except_filters: this.parentData.export_attr.except_filters,

      loading_fields: [],
      loading: true,
      form_valid: false,

      with_filters:
        typeof this.parentData.export_attr.data.form.with_filters === "undefined"
          ? false
          : this.parentData.export_attr.data.form.with_filters,

      sheet_data: [],
    };
  },

  computed: {
    header_title() {
      return this.parentData.export_attr.header_title;
    },

    form() {
      return this.parentData.export_attr.data.form;
    },

    fields() {
      return this.parentData.export_attr.data.fields;
    },
  },

  watch: {
    loading(newVal, oldVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"].setLoading(newVal);
      }
    },
  },

  created() {
    this.resetForm();
    this.fieldsLoaded();
  },

  methods: {
    closeForm() {
      this.resetForm();
      this.$emit("close-export", {});
    },

    resetForm() {
      let fields = Object.keys(this.currentForm);
      this.$store.commit("resetFormAllFields", { grid_name: this.grid, fields: fields });
    },

    getFieldComponent(key) {
      return fields[key];
    },

    getFieldData(field) {
      return this.fields.filter(f => f == field);
    },

    fieldsLoaded(field = undefined) {
      if (this.loading_fields.length === 0) {
        this.loading_fields = this.fields
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      if (field) {
        this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString());
      }

      if (this.loading_fields.length === 0) {
        this.loading = false;
      }
    },

    isValid() {
      this.checkFormValidation(this.currentForm);

      return this.form_valid;
    },

    onExport() {
      let fn;
      let attr = {};
      attr["report_params"] = {};
      attr["report_params"]["filters"] = {};

      if (this.collection_type) {
        attr["report_params"]["collection_type"] = this.collection_type;
      }

      if (this.export_type) {
        attr["report_type"] = this.export_type;
      }

      if (this.with_filters) {
        attr["report_params"]["filters"] = this.generateFiltersParams();
      }

      if (this.except_filters) {
        attr["report_params"]["except_filters"] = this.except_filters;
      }

      if (this.sti_type) {
        attr["report_params"]["filters"]["type"] = this.sti_type;
      }

      if (this.report_class) {
        attr["report_params"]["report_class"] = this.report_class;
      }

      if (this.report_namespace) {
        attr["report_params"]["report_namespace"] = this.report_namespace;
      }

      if (!this.isValid()) {
        return;
      }

      this.loading = true;
      this.loadReport(attr);
    },

    generateFiltersParams() {
      return Object.keys(this.currentForm).reduce((result, e) => {
        let field = this.currentForm[e]["field"];

        if (field) {
          if (Array.isArray(this.currentForm[e]["field"])) {
            result[e] = field.map(e => {
              if (e["value"]) {
                return e["value"];
              } else {
                return e;
              }
            });
          } else if (typeof field === "object") {
            result[e] = field["value"];
          } else {
            result[e] = field;
          }
        }
        return result;
      }, {});
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/modal-form";
@import "../../../assets/styles/forms/fields/checkbox";
</style>
