<template lang="pug">
div
  div(v-if="column.type === 'image'", @click.self="openShow()")
    img.grid-image(v-if="row[column.name]", :src="row[column.name]['url']")

  div(v-else-if="column.type === 'files_collection'")
    inline-svg.files-collection-icon(
      v-if="row[column.name].length > 0",
      :src="require('../../../assets/icons/grid/clip.svg')",
      @click="openFilesModal()"
    )

  div(v-else-if="column.type === 'icon'")
    q-icon.td-icon(:name="row.icon")

  div(v-else, @click.self="openShow()")
    span(:class="[addColumnClass()]")
      | {{ handleRow() }}

      .td-title(:id="`td-title-${row.id}-${column.name}`")
        .title-msg
        .title-arrow
</template>

<script>
export default {
  components: {},

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      grid_key: this.parentData.grid_key,
    };
  },

  computed: {
    column() {
      return this.parentData.column;
    },

    row() {
      return this.parentData.row;
    },
  },

  created() {},

  mounted() {},

  methods: {
    openShow() {
      this.$emit("open-show", this.row);
    },

    openFilesModal(attr = {}) {
      attr["row"] = this.row;
      attr["files"] = this.row[this.column.name];
      this.$emit("open-files-modal", attr);
    },

    addColumnClass() {
      let column_class = "";
      if (this.column.type === "issue_state") {
        column_class = `issue-state ${this.row["state"]}-badge`;
      }
      return column_class;
    },

    handleRow() {
      let result = this.row[this.column.name];
      if (this.column.type === "boolean") {
        result = this.row[this.column.name] ? "Да" : "Нет";
        if (this.row[this.column.name] === undefined) {
          result = "";
        }
      } else if (this.column.type === "description") {
        result = this.handleDescriptionTd(this.row[this.column.name]);

        if (result && result.length > 50) {
          this.addTitleEvent(result);
          result = result.substring(0, 50) + "...";
        }
      } else if (["datetime", "date"].includes(this.column.type)) {
        result = this.handleDateRow(this.row, this.column.name, this.column.format);
      } else if (this.column.type === "float") {
        const precision = this.column["precision"] || 2;
        const val = this.row[this.column.name] || 0;
        result = val.toFixed(precision);
      }

      if (result && result.length > 50) {
        this.addTitleEvent(result);
        result = result.substring(0, 50) + "...";
      }

      return result;
    },

    addTitleEvent(title) {
      this.$nextTick(() => {
        let td_el = document.getElementById(`td-${this.row.id}-${this.column.name}`);
        let title_el = document.getElementById(`td-title-${this.row.id}-${this.column.name}`);
        let grid_el = document.getElementById(`grid-${this.grid_key.toString()}`);

        td_el.addEventListener("mouseover", () => {
          this.showTitle(td_el, title_el, title);
        });
        td_el.addEventListener("mouseleave", () => {
          this.hideTitle(title_el);
        });

        if (grid_el) {
          grid_el.addEventListener("scroll", () => {
            this.hideTitle(title_el);
          });
        }
      });
    },

    showTitle(td_el, title_el, title) {
      let msg = title_el.querySelector(".title-msg");

      if (msg.children.length === 0) {
        let span = document.createElement("span");
        span.textContent = title;
        msg.appendChild(span);
      }

      title_el.style.visibility = "visible";
      title_el.style.opacity = "1";
      title_el.style.top = td_el.getBoundingClientRect().top - title_el.clientHeight + "px";
      title_el.style.left = td_el.getBoundingClientRect().left + 20 + "px";
    },

    hideTitle(title_el) {
      title_el.style.opacity = "0";
      title_el.style.visibility = "hidden";
      let msg = title_el.querySelector(".title-msg");
      while (msg.hasChildNodes()) {
        msg.removeChild(msg.firstChild);
      }
    },
  },
};
</script>

<style lang="scss"></style>
