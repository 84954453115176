<template lang="pug">
q-card#form.modal-form-card
  q-card-section
    form-header(
      ref="form-header",
      @close-form="closeForm",
      :parentData="{ grid: grid, title: import_excel_locales.form_header_title, title_icon: 'file_header.svg' }"
    )

  .spinner-container.modal-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  div(v-show="!loading")
    q-card-section
      div
        .form-field-label
          span {{ import_excel_locales.upload_template }}
        a(:href="href", @click="excelTemplate($event)") {{ import_excel_locales.file_link }}

    q-card-section
      file-field(:parentData="file_data")
      info-field(v-if="logs.length > 0", :parentData="info_data")

    q-card-actions.button-group.row.justify-center(v-show="!loading")
      q-btn.modal-form-cancel(flat, no-caps, :label="import_excel_locales.cancel", @click="closeForm()")
      q-btn.modal-form-submit(flat, no-caps, :label="import_excel_locales.upload", @click="importExcel()")
</template>

<script>
import formHeader from "../../shared/forms/formHeader";
import fields from "./fields";
import fileField from "./fields/file_field";
import infoField from "./fields/info_field";

export default {
  components: {
    fileField,
    infoField,
    formHeader,
    fields,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      path: this.parentData.path,
      fields: this.parentData.import_attr.data,
      href: this.parentData.import_attr.href,
      grid: this.parentData.grid,
      collection_type: this.parentData.collection_type,

      loading_fields: [],
      loading: true,

      with_filters: false,

      sheet_data: [],
      logs: "",
    };
  },

  computed: {
    import_excel_locales() {
      return this.locales.import_excel[this.current_locale];
    },

    file_data() {
      return {
        data: [
          {
            available_format: {
              names: [".xslx"],
              types: ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
            },
            label: this.import_excel_locales.upload_file,
            multiple: false,
            name: this.parentData.grid + "_from_excel",
            type: "file",
          },
        ],
        grid: this.parentData.grid,
      };
    },

    info_data() {
      return {
        data: [
          {
            label: this.import_excel_locales.logs,
            name: this.parentData.grid + "_logs",
            type: "info",
            value: this.logs,
          },
        ],
        grid: this.parentData.grid,
      };
    },
  },

  watch: {
    loading(newVal, oldVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"].setLoading(newVal);
      }
    },
  },

  created() {
    this.resetForm();
    this.fieldsLoaded();
  },

  methods: {
    closeForm() {
      this.resetForm();
      this.$emit("close-import", {});
    },

    resetForm() {
      let fields = Object.keys(this.currentForm);
      this.$store.commit("resetFormAllFields", { grid_name: this.grid, fields: fields });
    },

    getFieldComponent(key) {
      return fields[key];
    },

    getFieldData(field) {
      return this.fields.filter(f => f == field);
    },

    fieldsLoaded(field = undefined) {
      if (this.loading_fields.length === 0) {
        this.loading_fields = this.fields
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      if (field) {
        this.loading_fields = this.loading_fields.filter(f => f.sort().toString() !== field.sort().toString());
      }

      if (this.loading_fields.length === 0) {
        this.loading = false;
      }
    },

    importExcel() {
      this.logs = "";
      let params = {};
      let form = this.currentForm;

      params[this.grid] = Object.keys(form).reduce((result, e) => {
        if (Array.isArray(form[e]["field"])) {
          result[e] = form[e]["field"].map(e => e["value"]);
        } else if (typeof form[e]["field"] === "object") {
          result[e] = form[e]["field"]["value"];
        } else {
          result[e] = form[e]["field"];
        }
        return result;
      }, {});

      let keys = Object.keys(params[this.grid]).filter(key => params[this.grid][key]);

      if (keys.length > 0) {
        this.loading = true;

        this.$backend
          .create(this.path + "/import_excel", null, params)
          .then(res => {
            if (res.status === 200) {
              this.$nextTick(() => {
                this.loading = false;

                if (res.data.length > 0) {
                  this.logs = `<p>${res.data.join("</p><p>")}</p>`;
                } else {
                  this.$emit("close-import", {});
                }

                this.$q.notify(this.notifies.success_updated_entry);
                this.$emit("reload-data");
              });
            }
          })
          .catch(error => {
            if (error.response) {
              this.reLogin(error.response.status);
            }
            this.$nextTick(() => {
              this.loading = false;

              this.logs = `<p>${error.response.data.errors}</p>`;
              // this.$q.notify(error.response.data.errors)
              this.$q.notify(this.notifies.error_ask_admin);
            });
            console.log(error.response.data.errors);
          });
      }
    },
    excelTemplate(event) {
      if (this.href === "") {
        event.preventDefault();

        this.loading = true;

        this.$backend
          .index(this.path + "/excel_template")
          .then(({ data }) => {
            this.simpleExportExcel(data);
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              this.reLogin(error.response.status);
            }

            this.error = true;
            this.loading = false;

            this.$q.notify(this.notifies.error_ask_admin);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/modal-form";
@import "../../../assets/styles/forms/fields/checkbox";
</style>
